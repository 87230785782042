let origin = "https://future.zhuneng.cn";
//创业导师列表
const getTeacherListUrl = `/gateway/hc-business/manageapi/advisor/getList`;

// 创业导师详情
const getTeacherDetailURL = `/gateway/hc-business/manageapi/advisor/detail`;
//新增创业导师
const addTeacherURL = `/gateway/hc-business/manageapi/advisor/submit`;
//创业导师标签下拉框列表查询
const getTeacherInfoURL = `/gateway/hc-business/manageapi/advisor/getInitInfo`;
//删除创业导师
const deleteTeacherURL = `/gateway/hc-business/manageapi/advisor/delete`;
//编辑手机号
const advisorEdit = `/gateway/hc-business/manageapi/advisor/advisorEdit`;
export {
  getTeacherListUrl,
  getTeacherDetailURL,
  addTeacherURL,
  getTeacherInfoURL,
  deleteTeacherURL,
  advisorEdit,
};
